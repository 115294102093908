// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

require("@popperjs/core")
require('datatables.net-bs5')

import $ from 'jquery';
window.jQuery = $;
window.$ = $;
global.$ = jQuery;
global.toastr = require("toastr")

import '../js/bootstrap_js_files.js'
import "@fortawesome/fontawesome-free/css/all"
import "../stylesheets/application"


// Import the specific modules you may need (Modal, Alert, etc)
//import { Tooltip, Popover } from "bootstrap"

// The stylesheet location we created earlier
require("../stylesheets/application.scss")

// If you're using Turbolinks. Otherwise simply use: jQuery(function () {
document.addEventListener("turbolinks:load", () => {
    // Both of these are from the Bootstrap 5 docs
    var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
    var tooltipList = tooltipTriggerList.map(function(tooltipTriggerEl) {
        return new Tooltip(tooltipTriggerEl)
    })

    var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
    var popoverList = popoverTriggerList.map(function(popoverTriggerEl) {
        return new Popover(popoverTriggerEl)
    })
})




// flatpickr
import flatpickr from 'flatpickr'
require("flatpickr/dist/flatpickr.css");
document.addEventListener('turbolinks:load', () => {
    flatpickr("[data-behavior='flatpickr']", {
      altInput: true,
      enableTime: true,
      dateFormat: "Y-m-d H:i",
      altFormat: "Y-m-d H:i",
    });
  })


Rails.start()
Turbolinks.start()
ActiveStorage.start()
